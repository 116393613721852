<template>
    <div class="ZhuzaiRoad absolute allh allw overflow-hidden">
        <canvas ref="ZhuzaiRoad" :height="CountLeftH" :width="CurrentW"
                :style="{'transform':'translateX('+(-MoveUnit * MoveCount)+'px)'}"
                class="absolute"></canvas>
        <canvas ref="ZhuzaiRoad1" :height="CountLeftH" :width="CurrentW"
                :style="{'transform':'translateX('+(-MoveUnit * MoveCount)+'px)'}"
                class="absolute zindex3 nobg"></canvas>
        <img @click="moveTable('left')" class="absolute pointer"
             src="../../../public/img/GameCenter/jiantou_left.png">
        <img @click="moveTable('right')" class="absolute pointer"
             src="../../../public/img/GameCenter/jiantou_right.png">
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'ZhuzaiRoad',
        computed: {
            ...mapState(['whdef', 'LangeuageAgent'])
        },
        props: {
            'LeftH': {
                default() {
                    return 0
                }, type: Number
            },
            'RoadList': {
                default() {
                    return {}
                }, type: Object
            },
            'IsAskRoad': [String, Boolean],
            'DefaultCount': {
                default() {
                    return 8
                }, type: Number
            },
            'ImgRootUrl': [String]
        },
        data() {
            return {
                Name: '',
                CurrentW: 1000,
                MoveCount: 0, //表格移动次数
                MoveUnit: 0, //表格移动单位
                MaxX: 0, //最大横坐标
                CountLeftH: 0,  //计算出来的高度
                // Timer: null,
                // asktimer: null,
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.Init();
            });
        },
        destroyed() {
            // clearTimeout(this.Timer);
        },
        watch: {
            'RoadList': {
                handler(n, o) {
                    if (this.ToString(n) === this.ToString(o)) return;
                    this.Drawimg();
                }, deep: true
            },
            'LeftH': {
                handler(n, o) {
                    this.Drawimg();
                }
            },
            'IsAskRoad': {
                handler(n, o) {
                    this.ShowAskRoad(n, 'ZhuzaiRoad', parseInt((this.CountLeftH / 6) - 4), 2);
                }
            },
        },
        methods: {
            Init() {    //初始化尺寸 图片路径
                let Name = this.$route.name;
                this.Name = Name;
            },
            Drawimg() { //需要更换成双缓冲绘图  解决闪屏  用于问路
                this.CountLeftH = this.CountCanvas(this.LeftH, 6);   //计算高度
                this.MaxX = 0;  //重置最大位移
                this.MoveCount = 0; //重置位移次
                this.MoveUnit = this.CountLeftH / 6;
                let _this = this;
                let canvas = this.$refs['ZhuzaiRoad'];
                let context = canvas.getContext('2d');
                let cacheCanvas = document.createElement('canvas');
                let cacheContext = cacheCanvas.getContext('2d');
                cacheCanvas.width = this.CurrentW;
                cacheCanvas.height = this.CountLeftH;
                let fn = (index, max) => {
                    if (index >= max) return;
                    let item = _this.RoadList['ZhuzaiRoad'][index];
                    let img = new Image();
                    if (_this.ImgRootUrl) {
                        img.src = `./img/${_this.ImgRootUrl}/${item.url}${_this.LangeuageAgent === 'CH' ? '' : _this.LangeuageAgent}.png`;
                    } else {
                        img.src = `./img/${localStorage.type}/${item.url}${_this.LangeuageAgent === 'CH' ? '' : _this.LangeuageAgent}.png`;
                    }
                    img.onload = function () {
                        if (item.x > _this.MaxX) _this.MaxX = item.x;
                        let num = parseInt((_this.CountLeftH / 6) - 4);
                        let x = num * item.x + (2 * (2 * item.x + 1));
                        let y = num * item.y + (2 * (2 * item.y + 1));
                        cacheContext.drawImage(img, x, y, num, num);
                        if (index === max - 1) {
                            canvas.height = _this.CountLeftH;
                            setTimeout(() => {
                                context.drawImage(cacheCanvas, 0, 0);
                            }, 0);
                            if (_this.MaxX >= _this.DefaultCount) _this.MoveCount = _this.MaxX - _this.DefaultCount;
                        }
                        fn(++index, max);
                    }
                };
                if (!this.RoadList['ZhuzaiRoad'] || this.RoadList['ZhuzaiRoad'].length === 0) {
                    canvas.height = this.CountLeftH;
                    return;
                }
                fn(0, this.RoadList['ZhuzaiRoad'].length);
            },
            moveTable(Direction) {
                Direction === 'left' && this.MoveCount > 0 && this.MoveCount--;
                Direction === 'right' && Math.abs(this.MoveCount) < this.MaxX && this.MoveCount++;
            }
        }
    }
</script>

<style lang="less">
    .ZhuzaiRoad {
        > img {
            width: .25rem;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            height: 100%;
            width: 1px;
            border-right: 1px solid rgba(28, 28, 28, 1);
            right: 0;
            top: 0;
        }

        &:hover {
            img {
                display: block;
            }
        }

        canvas {
            transition: .3s;
            left: 0;
            top: 0;
            background: url("../../../public/img/Baccarat/dbj.png") repeat-x top left/contain;

            &.nobg {
                background: none;
            }
        }

        img {
            z-index: 10;
            width: .3rem;
            height: .3rem;
            top: 50%;
            transform: translate(0, -50%);
            display: none;
            left: .15rem;

            &:last-child {
                left: auto;
                right: .15rem;
            }
        }
    }
</style>
