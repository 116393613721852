<template>
    <div class="GameCenterCattle allw flex column border-box pal10 par10 pointer">
        <div class="gameinfo flex">
            <div class="flex">
                <!--                <span class="font30">{{CopyTableInfo.betTime < 0 ? 0 : CopyTableInfo.betTime}}</span>-->
                <span class="font20">{{Lang['NN']}}<!--牛牛-->{{CopyTableInfo.gameName}}</span>
                <!--                mal20-->
            </div>
            <div class="flex content-between">
                <span class="font20 flex1">{{Lang['XH']}}<!--限红-->：{{CopyTableInfo.limitRed}}</span>
                <span class="colorfff font20">0</span>
            </div>
        </div>
        <div class="flex1 backgroundWhite lz flex relative">
          <span :class="{'spancircle1':CopyTableInfo.betTime > 0}" class="absolute colorccc font35 position-center zindex3">
			{{CopyTableInfo.betTime <= 0 ? GetGameStatus(CopyTableInfo) : CopyTableInfo.betTime}}
          </span>
            <CattleRoad :RoadList="RoadList"></CattleRoad>
        </div>
        <div class="resultinfo flex content-between border-box">
            <div :class="[LangeuageAgent === 'CH' ? 'font20' : 'font13']" class="flex1 flex items-center colorfff">
                <span class="flex1"><font class="colorx">{{Lang['XJ']}}<!--闲-->1:</font>{{ResultNumber && ResultNumber[0]}}</span>
                <span class="flex1"><font class="colorx">{{Lang['XJ']}}<!--闲-->2:</font>{{ResultNumber && ResultNumber[1]}}</span>
                <span class="flex1"><font class="colorx">{{Lang['XJ']}}<!--闲-->3:</font>{{ResultNumber && ResultNumber[2]}}</span>
            </div>
            <span :class="[LangeuageAgent === 'CH' ? 'font24' : 'font15']" class="pointer">{{Lang['KSYX']}}<!--开始游戏--></span>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import CattleRoad from '../Canvas/CattleRoad';

    export default {
        name: 'Cattle',
        props: ['TableInfo'],
        components: {CattleRoad},
        computed: {
            ...mapState(['LangeuageAgent'])
        },
        data() {
            return {
                CopyTableInfo: {},
                ResultNumber: [0, 0, 0],
                Timer: null,
                Timer1: null,
                RoadList: [],
            }
        },
        destroyed() {
            clearTimeout(this.Timer);
            clearTimeout(this.Timer1);
        },
        watch: {
            'TableInfo': {
                handler(n, o) {
                    if (JSON.stringify(n) === JSON.stringify(o)) return;  //防止其他卓更新 当前卓也更新
                    this.CopyTableInfo = this.Clone(n);
                    if (JSON.stringify(n) !== JSON.stringify(o) && n.change === true) {
                        this.RoadList = n.transForm;
                        let dataarr = [0, 0, 0, 0];
                        let arr = this.Clone(n.transForm);
                        let fn = (obj, index) => {
                            if (obj < 0) return;
                            dataarr[index] += 1;
                            dataarr[3] += 1;
                        };
                        if (Array.isArray(arr)) {
                            arr.forEach((item, index) => {
                                let [a, b, c, d] = item;
                                fn(b, 0);
                                fn(c, 1);
                                fn(d, 2);
                            });
                        }
                        this.ResultNumber = this.Clone(dataarr);
                    }
                }, deep: true, immediate: true
            },
            'CopyTableInfo.betTime': {  //监听倒计时
                handler(n) {
                    clearTimeout(this.Timer1);
                    if (n > 0 && this.CopyTableInfo.gameStatus === 1) this.Timer1 = setTimeout(() => {
                        this.CopyTableInfo.betTime--;
                    }, 1000);
                    if (this.CopyTableInfo.gameStatus !== 1) {
                        this.CopyTableInfo.betTime = 0;
                    }
                }, deep: true, immediate: true
            }
        },
        created() {
            this.$nextTick(() => {
            });
        },
        methods: {}
    }
</script>

<style lang="less">
    .GameCenterCattle {
        height: 2.4rem;
        background: url("../../../public/img/GameCenter/list_bg.png") no-repeat top center/100% 100%;
        transition: .3s;

        .gameinfo {
            height: .3rem;

            div {
                line-height: .3rem;
            }

            div:first-child {
                width: 40%;

                /*span:first-child {*/
                /*    color: #c40000;*/
                /*    width: .35rem;*/
                /*}*/

                span:last-child {
                    color: #af8868;
                }
            }

            div:last-child {
                width: 60%;

                span:first-child {
                    color: #af8868;
                }

                span:last-child {
                    padding-left: .3rem;
                    background: url("../../../public/img/GameCenter/l_x.png") no-repeat 0.05rem center/.2rem .2rem;
                }
            }
        }

        .lz {

        }

        .resultinfo {
            height: .45rem;
            padding: .025rem 0;

            > div {
                margin-right: .3rem;
                width: 50%;
            }

            > span:last-child {
                line-height: .4rem;
                color: #c5c5c5;
                background-color: #3a3a3a;
                border-radius: .05rem;
                padding: 0 .3rem;

                &:hover {
                    background: url("../../../public/img/GameCenter/ksyxi_btn_hover.png") no-repeat center center/cover;
                    color: #291c03;
                }
            }
        }
    }
</style>
