<template>
    <div class="Notice allw flex">
        <div class="flex1 allh flex items-center border-box">
            <img class="mar15" src="../../../public/img/GameCenter/list_gonggao.png">
            <marquee class="font20" behavior="" direction="">{{Tips}}</marquee>
        </div>
        <div class="tools-box flex items-center">
            <div v-for="(item,index) in ToolArray" @click="ToolsAble(index)" class="pointer mal15 mar15">
                <img :src="'./img/GameCenter/' + item.src + '.png'" :style="{'height':item.h + 'rem'}">
                <img :src="'./img/GameCenter/' + item.src + '_hover.png'" :style="{'height':item.h + 'rem'}">
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'index',
        computed: {...mapState(['Tips', 'LangeuageAgent'])},
        data() {
            return {
                ToolArray: [{
                    src: 'huodong',
                    h: '0.35',
                }, {
                    src: 'xiugaimima',
                    h: '0.34',
                }, {
                    src: 'shipin',
                    h: '0.39',
                }, {
                    src: 'shezhi',
                    h: '0.37',
                }, {
                    src: 'bangzhu',
                    h: '0.38',
                }, {
                    src: 'quanpian',
                    h: '0.36',
                }, {
                    src: 'tuichu',
                    h: '0.38',
                }]
            }
        },
        methods: {
            ToolsAble(Index) {
                if (Index === 6) {  //退出到首页
                    this.SocketApi.CloseSock().then(() => {
                        sessionStorage.Heartbeat = false;
                        if (sessionStorage.LaunchToken) {  //如果时自动登录只需要弹出提示
                            this.Dialog(this.Lang['NYLKYX'], {Time: 9999999999999, Qr: false});//'您已离开游戏，请关闭浏览器'
                        } else {
                            this.Toast('1', this.Lang['TCCG'], {//'退出成功'
                                CallBack: () => {
                                    this.$router.replace('/');
                                }
                            });
                        }
                    });
                } else if (Index === 5) {
                    this.HandleFullScreen();
                } else if (Index === 4) {
                    this.SetStore({
                        Prop: 'ShowGameRules',
                        Val: true
                    });
                } else if (Index === 3) {
                    this.SetStore({
                        Prop: 'ShowVoice',
                        Val: true
                    });
                } else if (Index === 1) {
                    if (!this.IsPermission()) return;
                    this.SetStore({
                        Prop: 'ShowPassword',
                        Val: true
                    });
                }
            }
        }
    }
</script>

<style lang="less">
    .Notice {
        height: .45rem;
        background-color: #190f13;

        > div:first-child {
            padding-left: .45rem;
            color: #dba15b;

            img {
                height: .28rem;
            }
        }

        .tools-box {
            div {
                img:last-child {
                    display: none;
                }

                &:hover {
                    img:first-child {
                        display: none;
                    }

                    img:last-child {
                        display: block;
                    }
                }
            }
        }
    }
</style>
