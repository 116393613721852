<template>
    <div class="GameHall allh allw flex">
        <LeftCenter></LeftCenter>
        <div class="flex1 flex column">
            <Notice></Notice>
            <div class="flex1 flex overflow-hidden mat10 border-box par15 pab15">
                <div class="flex column flex1 mal5 mar5">
                    <div class="type-box flex mab5">
                        <div v-for="(item,index) in GameType" :key="index" @click="GetHall(index)"
                             :class="[CurrentType === index && 'currentType',LangeuageAgent === 'CH' ? 'font24' :'font18']"
                             class="text-center mar10 pointer">
                            {{Lang[item.name]}}
                        </div>
                    </div>
                    <div @mousewheel="WhellControl('WhellHallDiv',$event)" ref="WhellHallDiv"
                         class="flex1 overflow-hidden">
                        <div class="content-box allw flex warp flex-start">
                            <div v-for="(item,index) in HallInfo" :key="index" @click="CurrentRouter(item,$event)">
                                <components :is="CurrentTypeName" :TableInfo="item"></components>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="service-box flex column">
                    <span class="font30">{{Lang['ZXZX']}}</span>    <!--最新资讯-->
                    <div class="carousel-box mat10 relative overflow-hidden mab25">
                        <div :style="{'transform':'translateX(-'+(MoveVal * 100)+'%)'}" class="flex">
                            <img v-for="(item,index) in BannerArray" :key="index"
                                 :src="'./img/GameCenter/' + item + '.jpg'">
                        </div>
                        <ul class="absolute allw flex content-center items-center">
                            <li v-for="(item,index) in BannerArray" :key="index"
                                :class="{'currentBanner':MoveVal == index}"
                                @click="MoveVal = index"
                                class="pointer"></li>
                        </ul>
                    </div>
                    <div class="currentvideo-box relative mab10">
                        <img class="absolute" src="../../../public/img/GameCenter/jiazai.png" alt="">
                        <img class="absolute"
                             :src="'./img/GameCenter/spjzz'+(LangeuageAgent === 'CH' ? '' : LangeuageAgent)+'.png'"
                             alt="">
                    </div>
                    <span :class="[LangeuageAgent === 'CH' ? 'font30' :'font20']">{{Lang['SSLTXX']}}</span>
                    <!--实时聊天信息-->
                    <div class="flex1 talk-box mat10"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import Notice from '@/components/Notice/index';
    import LeftCenter from '@/components/LeftCenter/index'
    import Baccarat from '@/components/Dew/Baccarat';
    import LonghuLake from '@/components/Dew/LonghuLake';
    import Cattle from '@/components/Dew/Cattle';
    import ThreeCounsellors from '@/components/Dew/ThreeCounsellors';
    import SameSize from '@/components/Dew/SameSize';
    import SingleDouble from '@/components/Dew/SingleDouble';
    import PushBobbin from '@/components/Dew/PushBobbin';
    // import ZhuangXianniu from '@/components/Dew/ZhuangXianniu';

    export default {
        name: 'GameHall',
        components: {
            Notice,
            LeftCenter,
            Baccarat,
            LonghuLake,
            Cattle,
            ThreeCounsellors,
            SameSize,
            SingleDouble,
            PushBobbin,
            // ZhuangXianniu,
        },
        computed: {
            ...mapState(['HallInfo', 'SocketFn', 'whdef', 'DefaultConfig', 'LangeuageAgent'])
        },
        data() {
            return {
                CallBack: ['CallGetHallFn'],
                GameType: [
                    {
                        name: 'BJL', params: {  //百家乐
                            'hallType': 1,
                            'gameType': 1,
                            'url': 'Baccarat',
                            'videoSize': 'small',
                            'currentTypeName': 'Baccarat'
                        }
                    },
                    {
                        name: 'JSBJL', params: {   //极速·百家乐
                            'hallType': 2,
                            'gameType': 1,
                            'url': 'BaccaratSpeed',
                            'videoSize': 'big',
                            'currentTypeName': 'Baccarat'
                        }
                    },
                    {
                        name: 'LH', params: {   //龙虎
                            'hallType': 1,
                            'gameType': 2,
                            'url': 'LonghuLake',
                            'videoSize': 'small',
                            'currentTypeName': 'LonghuLake'
                        }
                    },
                    {
                        name: 'JSLH', params: {    //极速·龙虎
                            'hallType': 2,
                            'gameType': 2,
                            'url': 'LonghuLakeSpeed',
                            'videoSize': 'big',
                            'currentTypeName': 'LonghuLake'
                        }
                    },
                    {
                        name: 'NN', params: {   //牛牛
                            'hallType': 2,
                            'gameType': 3,
                            'url': 'Cattle',
                            'videoSize': 'small',
                            'currentTypeName': 'Cattle'
                        }
                    },
                    {
                        name: 'SG', params: {   //三公
                            'hallType': 2,
                            'gameType': 6,
                            'url': 'ThreeCounsellors',
                            'videoSize': 'small',
                            'currentTypeName': 'ThreeCounsellors'
                        }
                    },
                    {
                        name: 'DXD', params: {  //大小点
                            'hallType': 1,
                            'gameType': 5,
                            'url': 'SameSize',
                            'videoSize': 'big',
                            'currentTypeName': 'SameSize'
                        }
                    },
                    {
                        name: 'DS', params: {   //单双
                            'hallType': 1,
                            'gameType': 4,
                            'url': 'SingleDouble',
                            'videoSize': 'big',
                            'currentTypeName': 'SingleDouble'
                        }
                    },
                    {
                        name: 'TTZ', params: {  //推筒子
                            'hallType': 1,
                            'gameType': 7,
                            'url': 'PushBobbin',
                            'videoSize': 'small',
                            'currentTypeName': 'PushBobbin'
                        }
                    },
                    // {
                    //     name: 'ZXN', params: {   //庄闲牛
                    //         'hallType': 1,
                    //         'gameType': 8,
                    //         'url': 'LonghuLake',
                    //         'videoSize': 'small',
                    //         'currentTypeName': 'ZhuangXianniu'
                    //     }
                    // }
                    ],
                CurrentType: '',
                CurrentTypeName: '',
                BannerArray: ['zxzx01', 'zxzx02', 'zxzx01'],
                MoveVal: 0,
                Timer: null,
                Copywhdef: 1,
            }
        },
        created() {
            this.$nextTick(() => {
                let index = 0;
                let Defaultroom = JSON.parse(sessionStorage.Defaultroom);
                let GameSpeedOrOrdinary = [];
                if (this.DefaultConfig.GameSpeedOrOrdinary) {
                    this.DefaultConfig.GameSpeedOrOrdinary.forEach((item, index) => {
                        if (item) {
                            this.GameType[index].params.videoSize = item === '1' ? 'small' : 'big';
                            GameSpeedOrOrdinary.push(this.GameType[index]);
                        }
                    });
                    this.GameType = this.Clone(GameSpeedOrOrdinary);
                }
                if (Defaultroom.GameType != 0 && Defaultroom.HallType != 0) {
                    this.GameType.forEach((item, i) => {
                        let params = item.params;
                        if (params.hallType === Number(Defaultroom.HallType) && params.gameType === Number(Defaultroom.GameType)) index = i;
                    });
                }
                this.Banner();
                this.GetHall(index);
            });
        },
        watch: {
            'HallInfo': {
                handler() {
                }, deep: true, immediate: true
            },
            'whdef': {
                handler(n, o) {
                    if (n == 0) return;
                    this.Copywhdef = n;
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        if (this.whdef == this.Copywhdef) this.WhellControl('WhellHallDiv', true);
                    }, 1500);
                }, immediate: true
            },
        },
        methods: {
            Banner() {
                this.Timer = setInterval(() => {
                    if (this.MoveVal == this.BannerArray.length - 1) {
                        this.MoveVal = 0;
                        return;
                    }
                    this.MoveVal++;
                }, 3000);
            },
            GetHall(Index) {
                if (this.CurrentType === Index) return;
                let {hallType, gameType, currentTypeName, url} = this.GameType[Index].params;
                localStorage.type = currentTypeName;
                localStorage.HallName = currentTypeName;
                this.CurrentType = Index;
                this.SocketApi.SendSock([this.stype.Hall, this.cmd.EnterHall, 0, {
                    hallType,
                    gameType
                }], 'CallGetHallFn');
            },
            CallGetHallFn(Resp) {
                let {rooms: Rooms} = this.Clone(Resp);
                Rooms = Rooms || [];
                let _Rooms = {};
                Rooms.forEach((item, index) => {
                    item.change = true; //默认处于变化阶段  是否重新渲染露珠
                    item.random = Math.random();  //防止重新获取大厅数据导致不变
                    let _roadList = item.roadList || [];
                    if (localStorage.type === 'ZhuangXianniu') {   //如果是庄闲牛 需要对结果进行正负重置  正数就是庄(0)  负数就是闲(1)
                        _roadList.forEach((l) => {
                            if (l[0] > 0) l[0] = 0
                            if (l[0] < 0) l[0] = 1
                        })
                    }
                    item.transForm = this.Calculation.road(_roadList, true);
                    _Rooms[item.gameId] = item;
                });
                this.SetStore({ //更新滚动消息
                    Prop: 'Tips',
                    Val: Resp.message
                });
                this.SetStore({
                    Prop: 'HallInfo',
                    Val: _Rooms
                });
                this.SetStore({
                    Prop: 'MultipleId',
                    Val: Resp.multiRooms
                });
                this.WhellContent = 0;
                this.WhellControl('WhellHallDiv', true);
                this.CurrentTypeName = localStorage.type;
            },
            CurrentRouter(item, e) {
                let {url, currentTypeName, videoSize} = this.GameType[this.CurrentType].params;
                if (e.target.tagName !== 'IMG') {
                    localStorage.currentType = this.CurrentType;
                    localStorage.type = currentTypeName;
                    localStorage.videoSize = videoSize;
                    this.RouterTo('GameIn', {'id': item.gameId});
                }
            }
        }
    }
</script>

<style lang="less">
    .GameHall {
        min-width: 1366px;
        min-height: 768px;
        background-color: #1C1518;

        .type-box {
            div {
                width: 1.3rem;
                line-height: .4rem;
                background: url("../../../public/img/GameCenter/ksyxi_btn.png") no-repeat center center/cover;
                border-radius: .05rem;
                color: #c5c5c5;

                &.currentType {
                    background: url("../../../public/img/GameCenter/ksyxi_btn_hover.png") no-repeat center center/cover;
                    color: #291c03;
                }

                &:hover {
                    background: url("../../../public/img/GameCenter/ksyxi_btn_hover.png") no-repeat center center/cover;
                    color: #291c03;
                }
            }
        }

        .content-box {
            transition: .2s;

            > div {
                flex: 0 0 49.5%;
                margin-bottom: .05rem;

                &:nth-child(2n + 1) {
                    margin-right: .025rem;
                }

                &:nth-child(2n) {
                    margin-left: .025rem;
                }
            }
        }

        .service-box {
            width: 3.35rem;

            span {
                color: #987866;
            }

            .carousel-box {
                height: 4.2rem;

                > div {
                    transition: .8s;

                    > img {
                        width: 3.35rem;
                        height: 4.2rem;
                    }
                }

                ul {
                    bottom: 0;
                    height: .35rem;
                    background-color: rgba(0, 0, 0, .3);

                    li {
                        width: .15rem;
                        height: .15rem;
                        list-style: none;
                        background-color: #3A393A;
                        border-radius: 50%;
                        margin: 0 .05rem;

                        &.currentBanner {
                            background-color: #D39F76;
                        }
                    }
                }
            }

            .currentvideo-box {
                height: 1.9rem;
                background-color: #0B0809;

                img:first-child {
                    width: .8rem;
                    left: 50%;
                    top: 50%;
                    margin-left: -.4rem;
                    margin-top: -.4rem;
                    animation: rotate 3s linear infinite;
                    transform-origin: center center;
                }

                img:last-child {
                    width: 1.8rem;
                    left: 50%;
                    bottom: .1rem;
                    margin-left: -.9rem;
                }
            }

            .talk-box {
                background-color: #0B0809;
            }
        }

        @keyframes rotate {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
</style>
