<template>
    <div class="GameCenterSameSize allw flex column border-box pal10 par10 pointer">
        <div class="gameinfo flex">
            <div class="flex">
                <!--                <span class="font30">{{CopyTableInfo.betTime < 0 ? 0 : CopyTableInfo.betTime}}</span>-->
                <span class="font20">{{Lang['DXD']}}<!--大小点-->{{CopyTableInfo.gameName}}</span>
                <!--                mal20-->
            </div>
            <div class="flex content-between">
                <span class="font20 flex1">{{Lang['XH']}}<!--限红-->：{{CopyTableInfo.limitRed}}</span>
                <span class="colorfff font20">0</span>
            </div>
        </div>
        <div class="flex1 backgroundWhite lz flex relative">
              <span :class="{'spancircle1':CopyTableInfo.betTime > 0}" class="absolute colorccc font35 position-center zindex3">
                {{CopyTableInfo.betTime <= 0 ? GetGameStatus(CopyTableInfo) : CopyTableInfo.betTime}}
              </span>
            <div ref="left" class="left-box relative">
                <ZhuzaiRoad :LeftH="LeftH" :RoadList="RoadList"></ZhuzaiRoad>
            </div>
            <div class="flex1 flex column right-box relative">
                <div ref="rightTop" class="right-top relative">
                    <MainRoad :RightTopH="RightTopH" :RoadList="RoadList"></MainRoad>
                </div>
                <div class="rightBottom flex1 flex">
                    <div class="bottomLeft flex column">
                        <div ref="leftBottomTop" class="bottomTop relative flex1">
                            <PathRoad :LeftBottomTop="LeftBottomTop" :RoadList="RoadList"></PathRoad>
                        </div>
                        <div ref="leftBottomBottom" class="bottomBottom flex1 relative">
                            <YueyouRoad :LeftBottomBottom="LeftBottomTop" :RoadList="RoadList"></YueyouRoad>
                        </div>
                    </div>
                    <div class="bottomRight flex1 flex column">
                        <div ref="RightBottomTop" class="bottomTop relative flex1">
                            <ObliqueRoad :RightBottomTop="LeftBottomTop" :RoadList="RoadList"></ObliqueRoad>
                        </div>
                        <div ref="RightBottomBottom" class="bottomBottom relative flex1">
                            <NumRoad :RightBottomBottom="LeftBottomTop" :RoadList="RoadList"></NumRoad>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="resultinfo flex content-between border-box">
            <div :class="[LangeuageAgent === 'CH' ? 'font20' : 'font13']" class="flex1 flex items-center colorfff">
                <span class="flex1"><font class="colorz">{{Lang['DXTD']}}<!--大-->:</font>{{ResultNumber && ResultNumber[0]}}</span>
                <span class="flex1"><font class="colorx">{{Lang['DXTX']}}<!--小-->:</font>{{ResultNumber && ResultNumber[1]}}</span>
                <span class="flex1"><font class="colorh">{{Lang['DXTT']}}<!--同-->:</font>{{ResultNumber && ResultNumber[2]}}</span>
            </div>
            <span :class="[LangeuageAgent === 'CH' ? 'font24' : 'font15']" class="pointer">{{Lang['KSYX']}}<!--开始游戏--></span>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import ZhuzaiRoad from '../Canvas/ZhuzaiRoad';
    import MainRoad from '../Canvas/MainRoad';
    import PathRoad from '../Canvas/PathRoad';
    import YueyouRoad from '../Canvas/YueyouRoad';
    import ObliqueRoad from '../Canvas/ObliqueRoad';
    import NumRoad from '../Canvas/NumRoad';

    export default {
        name: 'SameSize',
        props: ['TableInfo'],
        components: {ZhuzaiRoad, MainRoad, PathRoad, YueyouRoad, ObliqueRoad, NumRoad},
        computed: {
            ...mapState(['whdef','LangeuageAgent'])
        },
        data() {
            return {
                CopyWhdef: 0,   //用于判断当前高度和未来高度是否相等
                LeftH: 0,  //猪仔路高度
                RightTopH: 0,  //大路高度
                LeftBottomTop: 0,  //小路高度  统一采用此高度
                // LeftBottomBottom: 0, //曰有高度
                // RightBottomTop: 0, //斜路高度
                // RightBottomBottom: 0,  //数字路高度
                CopyTableInfo: {},
                RoadList: [],
                ResultNumber: null,
                Timer: null,
                Timer1: null,
            }
        },
        destroyed() {
            clearTimeout(this.Timer);
            clearTimeout(this.Timer1);
        },
        watch: {
            'whdef': {
                handler(n, o) {
                    if (n === 0) return;
                    this.CopyWhdef = n;
                    clearTimeout(this.Timer);
                    this.Timer = setTimeout(() => {
                        if (this.whdef === this.CopyWhdef) this.ComputedHeight();
                    }, 1000);
                }
            },
            'TableInfo': {
                handler(n, o) {
                    if (JSON.stringify(n) === JSON.stringify(o)) return;  //防止其他桌子更新 当前卓也更新
                    this.CopyTableInfo = this.Clone(n);
                    if (JSON.stringify(n) !== JSON.stringify(o) && n.change === true) {
                        this.RoadList = n.transForm;
                        this.ResultNumber = this.RoadList['resultNumber'];
                    }
                }, deep: true, immediate: true
            },
            'CopyTableInfo.betTime': {  //监听倒计时
                handler(n) {
                    clearTimeout(this.Timer1);
                    if (n > 0 && this.CopyTableInfo.gameStatus === 1) this.Timer1 = setTimeout(() => {
                        this.CopyTableInfo.betTime--;
                    }, 1000);
                    if (this.CopyTableInfo.gameStatus !== 1) {
                        this.CopyTableInfo.betTime = 0;
                    }
                }, deep: true, immediate: true
            }
        },
        created() {
            this.$nextTick(() => {
                this.ComputedHeight();
            });
        },
        methods: {
            ComputedHeight() {
                this.LeftH = this.$refs['left'].clientHeight;
                this.RightTopH = this.$refs["rightTop"].clientHeight;
                this.LeftBottomTop = this.$refs["leftBottomTop"].clientHeight;
                // this.LeftBottomBottom = this.$refs["leftBottomBottom"].clientHeight;
                // this.RightBottomTop = this.$refs["RightBottomTop"].clientHeight;
                // this.RightBottomBottom = this.$refs["RightBottomBottom"].clientHeight;
            }
        }
    }
</script>

<style lang="less">
    .opacity0 {
        opacity: 0;
    }

    .GameCenterSameSize {
        height: 2.4rem;
        background: url("../../../public/img/GameCenter/list_bg.png") no-repeat top center/100% 100%;
        transition: .3s;

        .gameinfo {
            height: .3rem;

            div {
                line-height: .3rem;
            }

            div:first-child {
                width: 40%;

                /*span:first-child {*/
                /*    color: #c40000;*/
                /*    width: .35rem;*/
                /*}*/

                span:last-child {
                    color: #af8868;
                }
            }

            div:last-child {
                width: 60%;

                span:first-child {
                    color: #af8868;
                }

                span:last-child {
                    padding-left: .3rem;
                    background: url("../../../public/img/GameCenter/l_x.png") no-repeat 0.05rem center/.2rem .2rem;
                }
            }
        }

        .lz {
            .left-box {
                width: 2.7rem;
            }

            .right-box {
                .right-top {
                    height: 50%;
                }

                .bottomLeft {
                    width: 2.1rem;
                }
            }
        }

        .resultinfo {
            height: .45rem;
            padding: .025rem 0;

            > div {
                margin-right: .3rem;
                width: 50%;
            }

            > span:last-child {
                line-height: .4rem;
                color: #c5c5c5;
                background-color: #3a3a3a;
                border-radius: .05rem;
                padding: 0 .3rem;

                &:hover {
                    background: url("../../../public/img/GameCenter/ksyxi_btn_hover.png") no-repeat center center/cover;
                    color: #291c03;
                }
            }
        }
    }
</style>
